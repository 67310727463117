import { reaction } from "mobx";
import { matchSorter } from "match-sorter";
import { wrap } from "comlink";

import { watchQuery } from "../graph";
import { fragmentPaper, fragmentTask, fragmentVideo, gql } from "../mobx/gql";

if (typeof window !== "undefined") {
  var workerThread = new Worker(
    new URL("../worker/vector.js", import.meta.url),
    { type: "module" }
  );
  var worker = wrap(workerThread);
}

export default class Search {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
    //
    let suggestionBoxTimeout;
    // for search suggestions, allow user clicks to proceed before unmounting
    reaction(
      () => this.isFocused,
      // hold open Search Suggestions, allowing click events to register before blur
      isFocused => {
        if (isFocused === null) {
          suggestionBoxTimeout = setTimeout(this.set.isFocused, 300, false);
        } else if (isFocused) {
          clearTimeout(suggestionBoxTimeout);
        }
      }
    );
    // for search suggestions, when no user input, clear entity preview
    reaction(
      () => this.hasQuery === false,
      noUserInput => {
        if (noUserInput && this.suggestedEntityInFocus) {
          this.set.suggestedEntityInFocus();
        }
      }
    );

    let debuffSuggestions;
    // for search suggestions, as user types, suggest entities
    reaction(
      () => [this.hasFilters, this.queryVariables.query],
      ([trending, query]) => {
        clearTimeout(debuffSuggestions);

        if (trending || this.hasQuery) {
          debuffSuggestions = setTimeout(async () => {
            try {
              if (this.filterStars) {
                const topResults = this.fuzzyMatch(
                  Object.values(this.downloadedStars).flat(),
                  query
                ).reduce((topResults, entity) => {
                  const type =
                    entity.__typename === "Tag" ? "Task" : entity.__typename;

                  topResults[type] ??= [];
                  topResults[type].push(entity);

                  return topResults;
                }, {});

                for (const [entity, results] of Object.entries(topResults)) {
                  this.set[`suggested${entity}s`](results);
                }
              } else {
                const limit = 2;

                await Promise.all([
                  this.searchPapers({ query, limit }).then(
                    this.set.suggestedPapers
                  ),
                  Promise.all(
                    ["Tasks", "Videos"].map(entity =>
                      this.query({
                        query,
                        limit,
                        gqlQuery: `search${entity}`
                      }).then(this.set[`suggested${entity}`])
                    )
                  )
                ]);
              }
            } catch (error) {
              if (error) {
                console.error(error);
              }
            }
          }, 300);
        }
      }
    );

    let debuffResults;
    // get search results
    reaction(
      () =>
        JSON.stringify({
          ...this.queryVariables,
          type: this.filterType,
          query: this.searchParamsQ,
          tag_list: this.filterTaskArray,
          filterStars: this.loadingStars ? undefined : this.filterStars
        }),
      () => {
        clearTimeout(debuffResults);

        if (this.filterType) {
          if (this.filterStars) {
            this.set.results(
              this.fuzzyMatch(
                this.downloadedStars[this.filterType],
                this.searchParamsQ
              )
            );

            return this.set.loadingResults(false);
          }

          this.set.results();
          this.set.loadingResults(true);

          debuffResults = setTimeout(async () => {
            try {
              const entity =
                this.filterType[0].toUpperCase() + this.filterType.slice(1);
              const query = this.searchParamsQ || this.queryVariables.query;
              const results =
                entity === "Papers"
                  ? await this.searchPapers({ query, limit: 50 })
                  : await this.query({
                      query,
                      gqlQuery: `search${entity}`,
                      resolveFunction: results =>
                        Object.values(results?.data ?? {})[0] ?? [],
                      limit: 100
                    });

              this.set.results(results);
              this.set.loadingResults(false);
            } catch (error) {
              if (error) {
                console.error(error);
              }
            }
          }, 300);
        }
      }
    );

    let debuffCount;
    // get counts
    reaction(
      () => this.queryVariables,
      () => {
        if (this.filterStars) {
          this.set.count(
            Object.fromEntries(
              Object.entries(this.downloadedStars).map(([type, stars]) => [
                type,
                stars.length
              ])
            )
          );
          this.set.loadingCount(false);
        } else {
          clearTimeout(debuffCount);
          this.set.loadingCount(true);

          debuffCount = setTimeout(async () => {
            try {
              const countEntries = await Promise.all(
                ["Papers", "Tasks", "Videos"].map(entity =>
                  this.query({
                    gqlQuery: `search${entity}Count`,
                    query: this.queryVariables.query
                  }).then(([{ count }]) => [entity.toLowerCase(), count])
                )
              );

              this.set.count(Object.fromEntries(countEntries));
              this.set.loadingCount(false);
            } catch (error) {
              if (error) {
                console.error(error);
              }
            }
          }, 300);
        }
      }
    );

    // downloaded stars, happens once
    reaction(
      () => [this.saves, this._.stars.loading === false],
      async ([saves, starsLoaded]) => {
        if (starsLoaded) {
          try {
            this.set.downloadedStars();
            this.set.loadingStars(true);

            await Promise.all(
              saves.map(([type, stars]) =>
                this.downloadStars(
                  type,
                  stars.map(id => `{id:"${id}"}`)
                ).then(([capitalizedType, entities]) => {
                  this.set[`suggested${capitalizedType}`](entities);
                  this.set.downloadedStars({
                    ...this.downloadedStars,
                    [capitalizedType.toLowerCase()]: entities
                  });
                })
              )
            );
          } catch (error) {
            console.error(error);
          } finally {
            this.set.loadingStars(false);
          }
        }
      }
    );

    reaction(
      () => this._.reader.whiteLabelled,
      isWhiteLabelled => {
        if (isWhiteLabelled) {
          workerThread?.terminate();
        }
      }
    );

    worker?.ready.then(this.set.vectorsSupported);
  }
  set = {
    userInput: (userInput = "") => {
      this.userInput = userInput;
    },
    searchParams: (searchParams = new URLSearchParams()) => {
      this.searchParams = searchParams;
    },
    filterSort: filterSort => {
      this.filterSort = filterSort;
    },
    filterTime: filterTime => {
      this.filterTime = filterTime;
    },
    filterTask: (filterTask = new Set()) => {
      if (this.filterTask?.size !== 0 || filterTask.size !== 0) {
        this.filterTask = filterTask;
      }
    },
    filterType: filterType => {
      this.filterType = filterType;
    },
    filterPublisher: filterPublisher => {
      this.filterPublisher = filterPublisher;
    },
    filterStars: filterStars => {
      this.filterStars = filterStars;
    },
    isFocused: (isFocused = false) => {
      this.isFocused = isFocused;
    },
    results: (results = []) => {
      this.results = results;
    },
    suggestedPapers: (suggestedPapers = []) => {
      this.suggestedPapers = suggestedPapers;
    },
    suggestedModels: (suggestedModels = []) => {
      this.suggestedModels = suggestedModels;
    },
    suggestedVideos: (suggestedVideos = []) => {
      this.suggestedVideos = suggestedVideos;
    },
    suggestedTasks: (suggestedTasks = []) => {
      this.suggestedTasks = suggestedTasks;
    },
    suggestedEntityInFocus: suggestedEntityInFocus => {
      this.suggestedEntityInFocus = suggestedEntityInFocus;
    },
    count: (count = {}) => {
      this.count = count;
    },
    loadingResults: (loadingResults = true) => {
      this.loadingResults = loadingResults;
    },
    loadingSuggestions: (loadingSuggestions = false) => {
      this.loadingSuggestions = loadingSuggestions;
    },
    loadingCount: (loadingCount = true) => {
      this.loadingCount = loadingCount;
    },
    loadingStars: (loadingStars = true) => {
      this.loadingStars = loadingStars;
    },
    downloadedStars: (downloadedStars = {}) => {
      this.downloadedStars = downloadedStars;
    },
    vectorsSupported: (vectorsSupported = false) => {
      this.vectorsSupported = vectorsSupported;
    }
  };
  // computed
  get showSuggestions() {
    return this.isFocused !== false; //|| true;
  }
  get queryVariables() {
    const acceptedTimes = new Set(["1", "2", "3", "5", "10"]);

    return {
      query: this.userInput.trim() || "*",
      tag_list: this.filterTaskArray,
      publisher: this.filterPublisher,
      sortBy: this.filterSort,
      filterStars: this.filterStars,
      downloadStars: this.downloadedStars,
      time: acceptedTimes.has(this.filterTime)
        ? `P${this.filterTime}Y`
        : undefined
    };
  }
  get hasQuery() {
    return this.userInput !== "";
  }
  get hasFilters() {
    return (
      this.filterStars !== undefined ||
      this.filterSort !== undefined ||
      this.filterPublisher !== undefined ||
      this.filterTime !== undefined ||
      this.filterTask.size !== 0
    );
  }
  get filterTaskArray() {
    return [...this.filterTask];
  }
  get saves() {
    const allowedEntities = new Set(["paper", "video", "tag"]);
    const saves = {};
    const folderName = this.filterStars;

    if (folderName) {
      const folder = this._.stars.dictionary[folderName] ?? [
        ...this._.stars.map.values()
      ];

      for (const { type, id } of folder) {
        if (allowedEntities.has(type)) {
          saves[`${type}s`] ??= [];
          saves[`${type}s`].push(id);
        }
      }
    }

    return Object.entries(saves);
  }
  get searchParamsQ() {
    return this.searchParams.get("q")?.trim() || "";
  }
  get suggestions() {
    const suggestions = [
      ...this.suggestedTasks.slice(0, 3),
      ...this.suggestedVideos.slice(0, 3)
    ];
    const papers = this.suggestedPapers;

    for (const paper of papers) {
      if (suggestions.length === 9) {
        break;
      }
      suggestions.push(paper);
    }

    return suggestions;
  }
  get queryVariablesString() {
    return JSON.stringify(this.queryVariables);
  }
  query = ({ gqlQuery, limit = 10 }) =>
    new Promise((resolve, reject) => {
      const type = this.filterType;
      const queryThatKickedOff = this.queryVariablesString;

      watchQuery(
        {
          query: this._.gql.get(gqlQuery),
          variables: { ...this.queryVariables, limit }
        },
        response => {
          if (
            this.filterType === type &&
            queryThatKickedOff === this.queryVariablesString
          ) {
            resolve(Object.values(response?.data ?? {})[0] ?? []);
          } else {
            reject();
          }
        },
        reject
      );
    });
  downloadStars = (type, stars) =>
    new Promise((resolve, reject) => {
      const capitalizedType = type[0].toUpperCase() + type.slice(1);
      const fragment = {
        papers: fragmentPaper,
        tags: fragmentTask,
        videos: fragmentVideo
      };

      watchQuery(
        {
          query: gql(
            `query saved${capitalizedType} {
              ${type} (
                options: { limit: ${stars.length} }
                where: { OR: [${stars.join("\n")}] }
                ) {
                  ${fragment[type]}
                }
            }`
          )
        },
        results => {
          const entities = results?.data?.[type] ?? [];

          resolve([type === "tags" ? "Tasks" : capitalizedType, entities]);
        },
        reject
      );
    });
  fuzzyMatch = (rows = [], userInput = "*", limit) => {
    if (userInput.length <= 1) {
      var results = rows;
    } else {
      const searchQuery = userInput.replaceAll("\\", "");
      // find which properties we're searching over
      const searchableColumns = new Set(rows.flatMap(Object.keys));
      //
      results = matchSorter(rows, searchQuery, {
        // return the sorted list of keys we want to search over, key order is ranking priority
        keys: [
          { key: "title" },
          { key: "tags.*.name" },
          { key: "name" },
          { key: "summary" },
          { key: "description" },
          { key: "paperID" }
        ].filter(({ key }) =>
          searchableColumns.has(key.includes(".") ? key.split(".")[0] : key)
        )
      });
    }

    return results.slice(0, limit);
  };
  updateUrlParams = (router, urlParams) => {
    const url = new URLSearchParams(this.searchParams);

    for (const param in urlParams) {
      url.delete(param);
      urlParams[param]?.forEach(value => url.append(param, value));
    }

    const queryString = url.toString();

    router.push(`/search${queryString ? `?${queryString}` : ""}`);
  };
  vectorSearch = ({ query, limit = 10 }) =>
    new Promise(async (resolve, reject) => {
      // we cant use if not supported and trying to search with query
      if (this.vectorsSupported === false && query !== "*") {
        return resolve([]);
      }
      // we can use if supported and searching, or just filter search
      const variables = { ...this.queryVariables, limit };
      const queryThatKickedOff = this.queryVariablesString;

      variables.vector =
        query === "*" ? undefined : await worker.toVector(query);

      watchQuery(
        {
          variables,
          query: this._.gql.get(
            `searchPapers2_${variables.vector ? "" : "no_"}vector`
          )
        },
        results => {
          if (queryThatKickedOff === this.queryVariablesString) {
            resolve(Object.values(results?.data ?? {})[0]);
          } else {
            reject();
          }
        },
        reject
      );
    });
  async searchPapers({ query, limit }) {
    const hasQuery = query !== "*";
    const searches = await Promise.all([
      hasQuery ? this.query({ gqlQuery: "searchPapers", limit, query }) : [],
      this.vectorSearch({ query, limit })
    ]);
    const results = [];

    for (
      let set = new Set(),
        i = 0,
        j = Math.max(searches[0]?.length, searches[1]?.length);
      i < j;
      i++
    ) {
      const paperFuzzy = searches[0][i];
      const paperVector = searches[1][i];

      if (paperFuzzy && set.has(paperFuzzy.id) === false) {
        set.add(paperFuzzy.id);
        results.push(paperFuzzy);
      }
      if (paperVector && set.has(paperVector.id) === false) {
        set.add(paperVector.id);
        results.push(paperVector);
      }
    }

    return results;
  }
}
